import {SupplierInvoiceModel} from "Apis/Models/SupplierInvoiceModel";
import {request} from "Apis/BaseApi";
import {queryCache, useMutation, useQuery} from "react-query";
import {OfferModel} from "Apis/Models/OfferModel";
import { UploadedFileModel } from "./Models/UploadedFileModel";

export const supplierInvoicesCacheKey = 'SupplierInvoices';
export const useSupplierInvoices = (queryFilter?: string, includeApproved: string = "false") => {
    const params = new URLSearchParams();

    if (queryFilter) {
        params.set('queryFilter', queryFilter);
    }

    if (includeApproved) {
        params.set('includeApproved', includeApproved);
    }

    return useQuery<SupplierInvoiceModel[]>([supplierInvoicesCacheKey, queryFilter, includeApproved], async () => {
        return request<SupplierInvoiceModel[]>({
            url: `/supplierInvoices?${params.toString()}`,
            method: 'GET'
        })
    })
}

export const supplierInvoiceCacheKey = 'SupplierInvoice';
export const useSupplierInvoice = (id: number) => {
    return useQuery<SupplierInvoiceModel>([supplierInvoiceCacheKey, id], async () => {
        return request<SupplierInvoiceModel>({
            url: `/supplierInvoices/${id}`,
            method: 'GET'
        })
    })
}

export const useSaveSupplierInvoice = () => {
    return useMutation(async (entry: Partial<SupplierInvoiceModel>): Promise<SupplierInvoiceModel> => {
        let response;

        if (entry.id) {
            response = await request<SupplierInvoiceModel>({
                url: `/supplierInvoices/${entry.id}`,
                method: 'PUT',
                data: entry
            })
        } else {
            response = await request<SupplierInvoiceModel>({
                url: `/supplierInvoices`,
                method: 'POST',
                data: entry
            })
        }
        return response;
    }, {
        onSuccess: () => {
            queryCache.invalidateQueries(supplierInvoiceCacheKey)
            queryCache.invalidateQueries(supplierInvoicesCacheKey)
        },
    })
}

export const availableSupplierInvoicesCacheKey = 'AvailableSupplierInvoices';
export const useAvailableSupplierInvoicesForCreditNote = (id: number, enabled) => {
    return useQuery<SupplierInvoiceModel[]>([availableSupplierInvoicesCacheKey, id], async () => {
        return request<SupplierInvoiceModel[]>({
            url: `/supplierInvoices/${id}/availableInvoices`,
            method: 'GET'
        })
    }, {
        enabled
    })
}

export const useAssignSupplierInvoiceLines = () => {
    return useMutation(async ({lines, orderId}: any) => {
        return await request({
            url: '/supplierInvoices/assignlines',
            method: 'POST',
            data: {
                lines,
                orderId
            }
        })
    }, {
        onSuccess: () => {
            queryCache.invalidateQueries(supplierInvoiceCacheKey)
        }
    })
}

export const useApproveSupplierInvoice = () => {
    return useMutation(async (id: number) => {
        return await request<OfferModel>({
            url: `/supplierInvoices/${id}/approve`,
            method: 'POST'
        })
    }, {
        onSuccess: () => {
            queryCache.invalidateQueries(supplierInvoiceCacheKey)
            queryCache.invalidateQueries(supplierInvoicesCacheKey)
        }
    })
}

export const useRejectSupplierInvoice = () => {
    return useMutation(async ({id, reason}: any) => {
        return await request<OfferModel>({
            url: `/supplierInvoices/${id}/reject`,
            method: 'POST',
            data: {
                reason
            }
        })
    }, {
        onSuccess: () => {
            queryCache.invalidateQueries(supplierInvoiceCacheKey)
            queryCache.invalidateQueries(supplierInvoicesCacheKey)
        }
    })
}

export const useDeleteSupplierInvoice = () => {
    return useMutation(async (id: number) => {
        return await request({
            url: `/supplierInvoices/${id}`,
            method: 'DELETE'
        })
    }, {
        onSuccess: () => {
            queryCache.invalidateQueries(supplierInvoiceCacheKey)
            queryCache.invalidateQueries(supplierInvoicesCacheKey)
        }
    })
}

export const useLinkSupplierInvoiceCreditNote = () => {
    return useMutation(async ({creditNoteId, invoiceId}: any) => {
        return await request({
            url: `/supplierInvoices/${creditNoteId}/link/${invoiceId}`,
            method: 'POST',
        })
    }, {
        onSuccess: () => {
            queryCache.invalidateQueries(supplierInvoiceCacheKey)
            queryCache.invalidateQueries(supplierInvoicesCacheKey)
        }
    })
}

export const useUploadSupplierInvoiceFile = () => {
    return useMutation(async (supplierInvoiceFile: any) => {
        return await request({
            url: `/supplierInvoices/${supplierInvoiceFile.supplierInvoiceId}/upload`,
            method: 'PUT',
            data: supplierInvoiceFile
        })
    }, {
        onSuccess: () => {
            queryCache.invalidateQueries(supplierInvoiceCacheKey)
            queryCache.invalidateQueries(supplierInvoicesCacheKey)
        }
    })
}

export const useDownloadSupplierInvoiceFile = () => {
    return useMutation(async (supplierInvoiceFile: any) => {
        return await request<UploadedFileModel>({
            url: `/supplierInvoices/${supplierInvoiceFile.id}/download`,
            method: 'GET',
        })
    })
}