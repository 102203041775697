import {InvoiceLineModel, InvoiceModel} from "Apis/Models/InvoiceModel";
import {roundNumber} from "Utils/NumberUtils";
import {SupplierInvoiceLineModel} from "../Apis/Models/SupplierInvoiceModel";

export const getInvoiceTypeAsString = (type: string) => {
    return type === 'Invoice' ? 'Faktura' : 'Kreditnota';
}

export const getInvoiceStateAsString = (invoice: InvoiceModel | null | undefined) => {
    switch (invoice?.state) {
        case "Paid":
            return "Betalt";
        case "Approved":
            return 'Godkendt';
        case "Rejected":
            return 'Afvist af installatør';
        case "WaitingForApproval":
            return 'Afventer godkendelse';
        case "Deprecated":
            return 'Afskrevet til tab';
        default:
            return "Kladde";
    }
}

export const getInvoiceStateColor = (invoice: InvoiceModel | null | undefined): 'Default' | 'Success' | 'Danger' | 'Warning' => {
    switch (invoice?.state) {
        case "Paid":
            return "Success";
        case "Approved":
            return 'Warning';
        case "Rejected":
            return 'Danger';
        case "WaitingForApproval":
            return 'Warning';
        case "Deprecated":
            return 'Danger';
        default:
            return "Default";
    }
}

export const getLineTotalExVat = (amount, unitSalesPrice, discount) => {
    return roundNumber(amount * (unitSalesPrice * (1 - (discount / 100))))
}

export const getLinesTotalExVat = (lines: (InvoiceLineModel[] | SupplierInvoiceLineModel[])) => {
    if (lines.length === 0) {
        return 0;
    }
    
    return lines.map(line => getLineTotalExVat(line.amount, line.unitSalesPrice, line.discount)).reduce((a, b) => a + b)
}


