import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import React from "react";
import { useHistory } from "react-router-dom";
import {SupplierModel} from "Apis/Models/SupplierModel";
import SupplierForm from "./Forms/SupplierForm";

export const CreateSupplierPage = () => {

    const history = useHistory();

    return (
        <DefaultPageLayout title={"Opret leverandør"}>
            <SupplierForm onSave={(supplier: SupplierModel) => history.push(`/purchases/suppliers/edit/${supplier.id}`)} />
        </DefaultPageLayout>
    )

}

export default CreateSupplierPage;
