import React, {useCallback, useState} from "react";
import Report from "../../../Assets/Icons/Report";
import Box from "../../../Components/Box";
import FlexRow from "../../../Components/FlexRow";
import DefaultPageLayout from 'Pages/Layouts/DefaultPageLayout';
import Spinner from "../../../Components/Spinner";
import {Table} from "antd";
import moment from "moment/moment";
import _ from 'lodash';
import {useInvoices} from "../../../Apis/InvoiceAdminApi";
import {InvoiceAdminModel, InvoiceModel} from "../../../Apis/Models/InvoiceModel";
import Pill from "../../../Components/Pill";
import {
    getInvoiceStateAsString,
    getInvoiceStateColor,
    getInvoiceTypeAsString,
    getLinesTotalExVat
} from "../../../Utils/InvoiceUtils";
import {getDateOnlyString} from "../../../Utils/DateUtils";
import {getNumberAsCurrency} from "../../../Utils/NumberUtils";
import {useHistory} from "react-router-dom";

const InvoicesAdminPage = () => {

    const history = useHistory();

    const [invoiceType, setInvoiceType] = useState<string>('')
    const [invoiceState, setInvoiceState] = useState<string>('')
    const [invoiceReceiver, setInvoiceReceiver] = useState<string>('')
    const [query, setQuery] = useState<string>('')
    const [page, setPage] = useState<number>(0);

    const { data: invoices, isLoading } = useInvoices(page, 50, invoiceState, invoiceType, invoiceReceiver, query);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const search = useCallback(_.debounce(async (searchFilter: string) => {
        setQuery(searchFilter)
    }, 300), [])

    return (
        <DefaultPageLayout title={"Faktura"} icon={<Report />}
                           beforeBox={
                               <Box style={{ marginBottom: 15, padding: 10 }}>
                                   <FlexRow>
                                       <input onChange={e => search(e.target.value)} type="text"
                                              className={"input input-invisible full-width"} placeholder={"Søg...."}/>
                                       <select placeholder={"Vælg type"}
                                               onChange={e => setInvoiceType((e.target.value))}
                                               style={{paddingRight: 20, marginRight: 20}}>
                                           <option value="">Vælg type</option>
                                           <option value="Invoice">Faktura</option>
                                           <option value="CreditNote">Kreditnota</option>
                                       </select>
                                       <select placeholder={"Vælg status"}
                                               onChange={e => setInvoiceState((e.target.value))}
                                               style={{paddingRight: 20, marginRight: 20}}>
                                           <option value="">Åbne</option>
                                           <option value="Draft">Kladde</option>
                                           <option value="WaitingForApproval">Afventer godkendelse</option>
                                           <option value="Approved">Godkendt af installatør</option>
                                           <option value="Rejected">Afvist af installatør</option>
                                           <option value="Paid">Betalt</option>
                                           <option value="Overdue">Over forfald</option>
                                           <option value="Deprecated">Afskrevet</option>
                                       </select>
                                       <select placeholder={"Vælg modtager"}
                                               onChange={e => setInvoiceReceiver((e.target.value))}
                                               style={{paddingRight: 20, marginRight: 20}}>
                                           <option value="">Alle</option>
                                           <option value="Internal">Interne faktura</option>
                                           <option value="External">Eksterne faktura</option>
                                       </select>
                                       ´ </FlexRow>
                               </Box>
                           }
        >
            <Table
                size={"small"}
                rowKey={'id'}
                dataSource={invoices?.results}
                loading={{indicator: <Spinner/>, spinning: isLoading}}
                onRow={(record) => {
                    return {
                        onClick: () => history.push(`/admin/approval/invoices/${record.id}`),
                    };
                }
                }
                pagination={{
                    pageSize: 50,
                    current: page + 1,
                    total: invoices?.totalResults,
                    onChange: (newPage, pageSize) => {
                        setPage(newPage - 1)
                    }
                }}
                rowClassName={'clickable'}
            >

                <Table.Column title={"Modtager"} dataIndex={"isInternal"} render={(text, record: InvoiceModel) => (
                    <FlexRow justify={"start"}>
                        <Pill color={!record.isInternal ? 'Warning' : 'Danger'} />
                        <FlexRow align={"start"} justify={"start"} direction={"column"}>
                            <span>
                                {record.isInternal ? 'Intern' : 'Slut kunde'}
                            </span>
                        </FlexRow>
                    </FlexRow>
                )} />

                <Table.Column title={"Type"} dataIndex={"invoiceType"} render={(text, record: InvoiceModel) => (
                    <FlexRow justify={"start"}>
                        <Pill color={getInvoiceStateColor(record)} />
                        <FlexRow align={"start"} justify={"start"} direction={"column"}>
                            <span>
                                {getInvoiceTypeAsString(record.invoiceType)}
                            </span>
                            <span style={{fontSize: '.7em'}}>
                                {getInvoiceStateAsString(record)}
                            </span>
                        </FlexRow>
                    </FlexRow>
                )} />

                <Table.Column title={"Underleverandør"} dataIndex={"organization"} render={(text, record: InvoiceAdminModel) => (
                    <FlexRow align={"start"} justify={"start"} direction={"column"}>
                            <span>
                                {record.organization.name}
                            </span>
                            <span style={{fontSize: '.7em'}}>
                                {record.organization.id}
                            </span>
                        </FlexRow>
                ) } />
                <Table.Column title={"Ordre"} dataIndex={"orderNumber"} render={(text, record: InvoiceAdminModel) => `#${record.order.orderNumber}` } />
                <Table.Column title={"Dato"} dataIndex={"date"} render={(text, record: InvoiceModel) => record.date ? getDateOnlyString(moment(record.date).toDate()) : '' } />
                <Table.Column title={"Overskrift"} dataIndex={"title"} />

                <Table.Column title={"Beløb excl. moms"} render={(text, invoice: InvoiceModel) => !!invoice.lines ? `${(getNumberAsCurrency(getLinesTotalExVat(invoice.lines)))} DKK` : '0 DKK'} />

            </Table>
        </DefaultPageLayout>
    )

}

export default InvoicesAdminPage