import React, {useCallback, useState} from 'react';
import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import {Space, Table} from "antd";
import Spinner from "Components/Spinner";
import FlexRow from "Components/FlexRow";
import {useHistory} from "react-router-dom";
import {SupplierInvoiceModel} from "Apis/Models/SupplierInvoiceModel";
import {getInvoiceTypeAsString} from "Utils/InvoiceUtils";
import moment from "moment";
import Pill from "Components/Pill";
import {getNumberAsCurrency} from "Utils/NumberUtils";
import {useSaveSupplierInvoice, useSupplierInvoices} from "Apis/SupplierInvoiceApi";
import Purchase from "Assets/Icons/Purchase";
import Box from "Components/Box";
import debounce from 'lodash/debounce';
import StyledButton from "../../../Components/StyledButton";

const SupplierInvoiceOverview = () => {

    const history = useHistory();

    const [searchFilter, setSearchFilter] = useState<string>('')
    const [includeApproved, setIncludeApproved] = useState<string>('')
    const [ saveMutation, { isLoading: isSavingMutation } ] = useSaveSupplierInvoice();


    const {data: supplierInvoices, isLoading} = useSupplierInvoices(searchFilter, includeApproved);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const search = useCallback(debounce(newValue => setSearchFilter(newValue), 300), [])

    const createSupplierInvoice = async (invoiceType: 'Invoice' | 'CreditNote') => {
        const response = await saveMutation({
            invoiceType: invoiceType
        })

        history.push(`/purchases/supplierinvoices/edit/${response!.id}`)
    }


    return (
        <DefaultPageLayout
            title={"Indkøbsfaktura"}
            icon={<Purchase />}
            beforeBox={
                <Box style={{marginBottom: 15, padding: 10}}>
                    <FlexRow>
                        <input onChange={e => search(e.target.value)} type="text" className={"input input-invisible full-width"} placeholder={"Søg...."}/>
                        <select placeholder={"Vælg status"} onChange={e => setIncludeApproved((e.target.value))} style={{paddingRight: 20, marginRight: 20}} >
                            <option value="false">Afventer godkendelse</option>
                            <option value="true">Alle</option>
                        </select>
                    </FlexRow>
                </Box>
            }
             fab={
                <Space>
                    <StyledButton color={'default'} disabled={isSavingMutation} loading={isSavingMutation} onClick={() => createSupplierInvoice('CreditNote')}>
                        Opret indkøbskreditnota
                    </StyledButton>
                     <StyledButton color={'primary'} disabled={isSavingMutation} loading={isSavingMutation} onClick={() => createSupplierInvoice('Invoice')}>
                        Opret indkøbsfaktura
                    </StyledButton>
                </Space>
            }
        >
            <Table
                size={"small"}
                rowKey={'id'}
                dataSource={supplierInvoices}
                loading={{indicator: <Spinner />, spinning: isLoading}}
                pagination={false}
                onRow={(record) => {
                    return {
                        onClick: () => history.push(`/purchases/supplierinvoices/${record.id}`),
                    };}
                }
                rowClassName={'clickable'}
            >
                <Table.Column title={"Type"} dataIndex={"invoiceType"} render={(text, record: SupplierInvoiceModel) => (
                    <FlexRow justify={"start"}>
                        <Pill color={record.invoiceType === 'Invoice' ? 'Success' : 'Warning'} />
                        <FlexRow align={"start"} justify={"start"} direction={"column"}>
                            <span>
                                {getInvoiceTypeAsString(record.invoiceType)}
                            </span>
                        </FlexRow>
                    </FlexRow>
                )} />

                <Table.Column title={"Type"} dataIndex={"invoiceType"} render={(text, record: SupplierInvoiceModel) => (
                    <FlexRow align={"start"} justify={"start"} direction={"column"}>
                        <span style={{fontWeight: 600}}>
                            {record.supplierName}
                        </span>
                        <span style={{fontSize: '.8em'}}>
                            CVR: {record.supplierVatNumber}
                        </span>
                    </FlexRow>
                )} />

                <Table.Column title={"Ref."} dataIndex={"orderReference"} />
                <Table.Column title={"Fakturadato"} dataIndex={"invoiceDate"} render={(value, record: SupplierInvoiceModel) => moment(record.invoiceDate).format('DD MMMM YYYY')} />
                <Table.Column title={"Beløb"} dataIndex={"totalInclVat"} render={(text, record) => (
                    `${getNumberAsCurrency(text)} DKK`
                )} />
            </Table>
        </DefaultPageLayout>
    )
}

export default SupplierInvoiceOverview
