import React from 'react';
import FlexRow from "Components/FlexRow";
import {getNumberAsCurrency, roundNumber} from "Utils/NumberUtils";
import {getLinesTotalExVat} from 'Utils/InvoiceUtils';

const FormInvoiceTotal = ({watch}) => {

    const total = getLinesTotalExVat(watch('lines') ?? [])

    return (
        <div style={{width: '30%'}}>
            <FlexRow justify={"space-between"}>
                <span>Subtotal</span>
                <span>{(getNumberAsCurrency(roundNumber(total)))} DKK</span>
            </FlexRow>
            <FlexRow justify={"space-between"}>
                <span>Moms</span>
                <span>{(getNumberAsCurrency(roundNumber(total * 0.25)))} DKK</span>
            </FlexRow>
            <FlexRow justify={"space-between"}>
                <span><b>I alt</b></span>
                <span>{(getNumberAsCurrency(roundNumber(total * 1.25)))} DKK</span>
            </FlexRow>
        </div>
    )
}

export default FormInvoiceTotal
