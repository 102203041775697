import React from 'react';
import {
    useAcceptKlarPrisTerms,
    useCreateKlarPrisAccount,
    useKlarPrisAccount,
    useKlarPrisTerms,
    useKlarPrisWholeSalers
} from "Apis/klarprisApi";
import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import FlexRow from "Components/FlexRow";
import StyledButton from "Components/StyledButton";
import {Col, Row} from "antd";
import WholeSalerItem from "Pages/Settings/Klarpris/Components/WholeSalerItem";
import Purchase from "Assets/Icons/Purchase";
import Link from "../../../Assets/Icons/Link";

const KlarPrisPage = () => {

    const {data: klarPrisAccount, isLoading: isLoadingKlarPrisAccount} = useKlarPrisAccount();
    const {data: klarPrisTerms, isLoading: isLoadingTerms} = useKlarPrisTerms(klarPrisAccount === null);
    const {data: wholesalers, isLoading: isLoadingWholesalers} = useKlarPrisWholeSalers(klarPrisAccount !== null);

    const [createKlarPrisAccount, {isLoading: isCreatingAccount }] = useCreateKlarPrisAccount();
    const [acceptKlarPrisTerms, {isLoading: isAcceptingTerms}] = useAcceptKlarPrisTerms();

    const createAccountAndAcceptTerms = async () => {
        await createKlarPrisAccount()

        await acceptKlarPrisTerms();
    }

    return (
        <DefaultPageLayout title={"Leverandører"} isLoading={isLoadingKlarPrisAccount || isLoadingTerms || isLoadingWholesalers} icon={<Purchase />}>

            {!klarPrisAccount && klarPrisTerms && (
                <div>
                    <h2>
                        Inden at du kan oprette forbindelse til dine leverandører igennem ASAP'en, skal du først oprette en KlarPris konto.
                    </h2>

                    <span>Du skal acceptere KlarPris vilkår:</span>

                    <div style={{maxHeight: '40vh', overflow: "scroll", backgroundColor: '#F5F5F2', padding: 20, borderRadius: 10, margin: '20px 0'}}>
                        <div dangerouslySetInnerHTML={{__html: klarPrisTerms}} />
                    </div>

                    <FlexRow justify={"end"}>
                        <StyledButton onClick={createAccountAndAcceptTerms} color={"primary"} disabled={isCreatingAccount || isAcceptingTerms} loading={isCreatingAccount || isAcceptingTerms}>Jeg acceptere vilkårne - Opret KlarPris konto</StyledButton>
                    </FlexRow>
                </div>

            )}

            <Row gutter={[20, 20]}>
                <Col sm={24}>
                    <div className={"clickable"} style={{boxShadow: '0px 3px 6px rgba(0,0,0,.16)', borderRadius: 6, padding: '10px 20px', borderLeftWidth: 10, borderLeftStyle: 'solid', borderLeftColor: '#F39324'}}>
                        <FlexRow style={{paddingBottom: 10}} justify={"space-between"}>
                            <span style={{fontSize: 14, color: '#9F9F9F'}}>
                                Forbindelse oprettet
                            </span>
                            <Link style={{fill: '#9F9F9F', height: 20, width: 20}} />
                        </FlexRow>

                        <img height={40} width={'auto'} src={"/images/Logo.png"} alt={`ELI A/S Logo`} />

                        <div style={{padding: "10px 0 10px 0", fontWeight: 600}}>
                            Du kan modtage elektroniske faktura til automatisk bogføring via ASAP'en via menuen Indkøb
                        </div>
                        <div>
                            Informer din leverandør om at du kan modtage EAN faktura / Elektroniske faktura på følgende EAN nummer: 5790002711176
                        </div>
                        <div>
                            <i>Dit eget CVR nummer skal være angivet som kunde på fakturaen</i>
                        </div>
                    </div>
                </Col>
                {wholesalers?.map(x => (<WholeSalerItem key={x.id} wholesaler={x} />))}
            </Row>

        </DefaultPageLayout>
    )
}

export default KlarPrisPage
