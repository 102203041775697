import {queryCache, useMutation, useQuery} from "react-query";
import {request} from "Apis/BaseApi";
import {SupplierModel} from "./Models/SupplierModel";

export const SUPPLIERS_CACHE_KEY = 'SUPPLIERS_CACHE_KEY';
export const useSupplier = (id: number, enabled: boolean = true) => {
    return useQuery<SupplierModel>([SUPPLIERS_CACHE_KEY, id], async () => {
        return await request<SupplierModel>({
            url: `/suppliers/${id}`,
            method: 'GET'
        });
    }, {
        enabled
    })
}

export const useSuppliers = (searchFilter?: string) => {
    return useQuery<SupplierModel[]>([SUPPLIERS_CACHE_KEY, 'SEARCH', searchFilter], async () => {
        let url = '/suppliers';

        if (searchFilter) {
            url += `?queryFilter=${searchFilter}`;
        }

        return await request<SupplierModel[]>({
            url,
            method: 'GET'
        });
    })
}

export const useSaveSupplier = () => {
    return useMutation(async (supplier: SupplierModel): Promise<SupplierModel> => {
        if (supplier.id) {
            return await request<SupplierModel>({
                url: `/suppliers/${supplier.id}`,
                method: 'PUT',
                data: supplier
            })
        } else {
            return await request<SupplierModel>({
                url: `/suppliers`,
                method: 'POST',
                data: supplier
            })
        }
    }, {
        onSuccess: () => {
            queryCache.invalidateQueries(SUPPLIERS_CACHE_KEY)
        }
    })
}
