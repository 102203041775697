import Purchase from "../../../Assets/Icons/Purchase";
import React, {useEffect, useMemo, useState} from "react";
import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import DocumentContainer from "Components/Document/DocumentContainer";
import PaperLayout from "Components/PaperLayout";
import FlexRow from "../../../Components/FlexRow";
import moment from "moment/moment";
import styles from "../../Projects/Offers/OfferForm.module.scss";
import {Col, DatePicker, Row, Select, Space, Tooltip, Upload} from "antd";
import {getNumberAsCurrency, roundNumber} from "../../../Utils/NumberUtils";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import {SupplierInvoiceLineModel, SupplierInvoiceModel} from "../../../Apis/Models/SupplierInvoiceModel";
import {getPaperInputClasses} from "../../../Utils/CssUtils";
import {useSuppliers} from "../../../Apis/SupplierApi";
import StyledButton from "../../../Components/StyledButton";
import {useHistory, useParams} from "react-router-dom";
import {
    useDownloadSupplierInvoiceFile,
    useSaveSupplierInvoice,
    useSupplierInvoice,
    useUploadSupplierInvoiceFile
} from "../../../Apis/SupplierInvoiceApi";
import {openSuccessNotification} from "../../../Utils/NotificationUtils";
import Notice from "Components/Notice";
import {b64toBlob, toBase64} from "../../../Utils/Base64Utils";
import {saveAs} from "file-saver";

const UpdateSupplierInvoicePage = () => {

    const { id } = useParams<{ id }>();
    const history = useHistory();

    const {data: suppliers} = useSuppliers();

    const { data: supplierInvoice } = useSupplierInvoice(id);
    const [ saveMutation, { isLoading: isSavingMutation } ] = useSaveSupplierInvoice();
    const [ uploadFileMutation, { isLoading: isUploadingFile } ] = useUploadSupplierInvoiceFile();
    const [ downloadFileMutation, { isLoading: isDownloadingFile } ] = useDownloadSupplierInvoiceFile();


    const [supplierSearch, setSupplierSearch] = useState<string>('');

    const {register, control, errors, watch, reset, handleSubmit, getValues} = useForm<SupplierInvoiceModel>({ mode: 'onBlur', defaultValues: { supplierInvoiceLines: []}});

    const {fields, append, remove} = useFieldArray({
        control,
        name: 'supplierInvoiceLines'
    })

    useEffect(() => {
        if (supplierInvoice) {
            reset(supplierInvoice)
        }
    }, [supplierInvoice, reset]);

    const getLineTotal = (line: SupplierInvoiceLineModel | null) => {
        if (line == null) {
            return 0;
        }

        return line.quantity * line.unitPriceExVat;
    }

    const lines = watch('supplierInvoiceLines');

    const total = useMemo(() => lines.map(l => getLineTotal(l)).reduce((a, b) => a + b, 0), [lines])

    const save = async (data: SupplierInvoiceModel) => {
        console.log('saving...')

        await saveMutation({...supplierInvoice, ...data});

        openSuccessNotification('Success', 'Indkøbsfakturaen blev gemt');
    }

    const uploadFileHandler = async (e) => {
        await save(getValues())

        const base64 = await toBase64(e.file)

        await uploadFileMutation({
            supplierInvoiceId: supplierInvoice?.id,
            fileContent: base64,
            contentType: e.file.type
        })

        openSuccessNotification('Success', 'Filen blev uploadet')
    }

    const downloadAttachment = async () => {
        const file = await downloadFileMutation(supplierInvoice);

        const blob = b64toBlob(file!.fileContent, file!.contentType)

        saveAs(blob, `${supplierInvoice?.invoiceNumber}.${file?.contentType.split('/')[1]}`)
    }

    return (
        <DefaultPageLayout title={`Opdater ${supplierInvoice?.invoiceType === 'Invoice' ? 'indkøbsfaktura' : 'indkøbskreditnota'}`} icon={<Purchase/>}>
            <DocumentContainer>
                <PaperLayout showFooter={false} beforePaper={(
                    <Notice type={!supplierInvoice?.uploadedFileId ? 'Danger' : 'Default'}>
                        <FlexRow justify={'space-between'} align={"center"}>
                            <FlexRow align={"start"}>
                                <Space>
                                    <Upload customRequest={uploadFileHandler} showUploadList={false}>
                                        <StyledButton disabled={isUploadingFile} loading={isUploadingFile}>Upload bilag</StyledButton>
                                    </Upload>

                                    {supplierInvoice?.uploadedFileId && (
                                        <StyledButton disabled={isDownloadingFile} loading={isDownloadingFile} onClick={downloadAttachment}>Download bilag</StyledButton>
                                    )}
                                </Space>
                            </FlexRow>
                            <Space>
                                <Tooltip title={"Du skal uploade et bilag til faktrauen "} overlayStyle={{zIndex: 99}}>
                                    <span>
                                        <StyledButton disabled={!supplierInvoice?.uploadedFileId} loading={isSavingMutation} onClick={() => history.push(`/purchases/supplierInvoices/${supplierInvoice?.id}`)} color={"default"}>Gå til godkendelse</StyledButton>
                                    </span>
                                </Tooltip>
                                <StyledButton disabled={isSavingMutation} loading={isSavingMutation} onClick={handleSubmit(save)} color={"primary"}>Gem</StyledButton>
                            </Space>
                        </FlexRow>
                    </Notice>
                )}>
                        <FlexRow justify={"space-between"}>

                            <div style={{width: '40%', fontSize: '.9em'}}>
                                <Row gutter={[5, 5]}>
                                    <Col span={24}>
                                        <Controller name={"supplierId"} defaultValue={null} control={control}
                                                    render={({onChange, value}) => (
                                                        <div className={getPaperInputClasses(errors.supplierName, true)}>
                                                            <Select
                                                                autoFocus
                                                                bordered={false}
                                                                showSearch
                                                                value={value}
                                                                className={"w-100"}
                                                                placeholder={'Vælg leverandør'}
                                                                filterOption={false}
                                                                onSelect={e => {
                                                                    onChange(e);
                                                                    setSupplierSearch('')
                                                                }}
                                                                onSearch={(value) => setSupplierSearch(value.toLowerCase())}
                                                                dropdownRender={menu => (
                                                                    <div>
                                                                        {menu}
                                                                    </div>
                                                                )}
                                                            >
                                                                {suppliers?.filter(x => x.name.toLowerCase().includes(supplierSearch)).map((customer) => (
                                                                    <Select.Option key={customer.id}
                                                                                   value={customer.id}>{customer.name}</Select.Option>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                    )}/>
                                    </Col>
                                    <Col span={24}>
                                        <FlexRow justify={"space-between"} className={getPaperInputClasses(errors.invoiceDate)} style={{paddingLeft: 11}}>
                                            Bilagsnr: <input name={"invoiceNumber"} ref={register({required: true})} required placeholder={"Bilagsnummer"} style={{paddingLeft: 8}} />
                                        </FlexRow>
                                    </Col>
                                    <Col span={24}>
                                        <FlexRow justify={"space-between"} className={getPaperInputClasses(errors.invoiceDate)} style={{paddingLeft: 11}}>
                                            Dato: <Controller name={'invoiceDate'} control={control}
                                              defaultValue={null}
                                              render={props => (
                                                      <DatePicker bordered={false}
                                                                  format={"DD-MM-YYYY"}
                                                                  placeholder={"Vælg bilagsdato"}
                                                                  size={"small"}
                                                                  className={"w-100"}
                                                                  value={props.value ? moment(props.value) : null}
                                                                  onChange={(e => {
                                                                      props.onChange(e?.toDate())
                                                                  })}
                                                      />
                                              )}
                                            />
                                        </FlexRow>
                                    </Col>
                                    <Col span={24}>
                                        <FlexRow justify={"space-between"} className={getPaperInputClasses(errors.invoiceDate)} style={{paddingLeft: 11}}>
                                            Forfald: <Controller name={'paymentDate'} control={control}
                                              defaultValue={null}
                                              render={props => (
                                                      <DatePicker bordered={false}
                                                                  format={"DD-MM-YYYY"}
                                                                  placeholder={"Vælg betalingsdato"}
                                                                  size={"small"}
                                                                  className={"w-100"}
                                                                  value={props.value ? moment(props.value) : null}
                                                                  onChange={(e => {
                                                                      props.onChange(e?.toDate())
                                                                  })}
                                                      />
                                              )}
                                            />
                                        </FlexRow>
                                    </Col>
                                </Row>
                            </div>
                        </FlexRow>

                        <FlexRow>
                            <div style={{fontWeight: 600, fontSize: 20, marginTop: 20, marginBottom: '10px'}}>
                                {supplierInvoice?.invoiceType === 'Invoice' ? 'Indkøbsfaktura' : 'Indkøbskreditnota'}
                            </div>
                        </FlexRow>

                        <hr className={styles.seperator}/>

                        <Row gutter={10}>
                            <Col span={13}>Tekst</Col>
                            <Col span={3}>Antal</Col>
                            <Col span={3}>Stk. pris</Col>
                            <Col span={4}>
                                <span style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    Pris
                                </span>
                            </Col>
                        </Row>

                        <hr className={styles.seperator}/>

                        <div style={{fontSize: '0.9em'}}>
                            {fields?.map((line, index) => (
                                <Row key={line.id} gutter={[10, 10]} align={'middle'}>
                                    <Col span={13}>
                                        <FlexRow direction={"column"} align={'start'}>
                                            <input type="text" defaultValue={line.lineText} className={getPaperInputClasses(errors.supplierInvoiceLines && errors.supplierInvoiceLines[index]?.lineText)} name={`supplierInvoiceLines[${index}].lineText`} ref={register({required: true, minLength: 2})} />
                                        </FlexRow>
                                    </Col>
                                    <Col span={3}>
                                        <input type="number"
                                               className={getPaperInputClasses(errors.supplierInvoiceLines && errors.supplierInvoiceLines[index]?.quantity)}
                                               name={`supplierInvoiceLines[${index}].quantity`}
                                               defaultValue={line.quantity}
                                               ref={register({required: true, min: 0.1, pattern: /^[0-9]+(\.[0-9]{1,2})?$/})}
                                               step=".01"
                                        />
                                    </Col>
                                    <Col span={3}>
                                        <input type="number"
                                               defaultValue={line.unitPriceExVat}
                                               className={getPaperInputClasses(errors.supplierInvoiceLines && errors.supplierInvoiceLines[index]?.unitPriceExVat)}
                                               name={`supplierInvoiceLines[${index}].unitPriceExVat`}
                                               ref={register({required: true, min: 0.1, pattern: /^[0-9]+(\.[0-9]{1,2})?$/})}
                                               step=".01"
                                        />
                                    </Col>
                                    <Col span={4}>
                                        <span style={{display: 'flex', justifyContent: 'flex-end'}}>
                                            {getNumberAsCurrency(getLineTotal(watch()?.supplierInvoiceLines ? watch().supplierInvoiceLines[index] : null))} DKK
                                        </span>
                                    </Col>
                                    <Col span={1}>
                                        <span style={{height: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                                            <span className={"clickable"} onClick={() => remove(index)} style={{marginLeft: 5}}>X</span>
                                        </span>
                                    </Col>
                                </Row>
                            ))}
                        </div>

                        <StyledButton onClick={append}>Tilføj linje</StyledButton>

                        <hr className={styles.seperator}/>

                        <FlexRow justify={"end"} align={'end'} direction={"column"}>
                            <div style={{width: '30%'}}>
                                <FlexRow justify={"space-between"}>
                                    <span>Subtotal</span>
                                    <span>{(getNumberAsCurrency(roundNumber(total)))} DKK</span>
                                </FlexRow>
                                <FlexRow justify={"space-between"}>
                                    <span>Moms</span>
                                    <span>{(getNumberAsCurrency(roundNumber(total * 0.25)))} DKK</span>
                                </FlexRow>
                                <FlexRow justify={"space-between"}>
                                    <span><b>I alt</b></span>
                                    <span>{(getNumberAsCurrency(roundNumber(total * 1.25)))} DKK</span>
                                </FlexRow>
                            </div>
                        </FlexRow>
                </PaperLayout>
            </DocumentContainer>
        </DefaultPageLayout>
    )

}

export default UpdateSupplierInvoicePage