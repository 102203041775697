import {Route, Switch, useRouteMatch} from "react-router-dom";
import React from "react";
import EditSupplierPage from "./EditSupplierPage";
import CreateSupplierPage from "./CreateSupplierPage";
import SuppliersOverviewPage from "./SuppliersOverviewPage";

const SupplierRoutes = () => {
    let {path} = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}`} exact>
                <SuppliersOverviewPage />
            </Route>

            <Route path={`${path}/create`}>
                <CreateSupplierPage />
            </Route>

            <Route path={`${path}/edit/:id`}>
                <EditSupplierPage />
            </Route>
        </Switch>
    )
}

export default SupplierRoutes;
