import React, {useEffect} from 'react';
import {Col, Dropdown, Row} from "antd";
import {getPaperInputClasses} from "Utils/CssUtils";
import FlexRow from "Components/FlexRow";
import FormLineTotal from "Pages/Economy/Components/FormLineTotal";
import {useProductsSearchMenu} from "Hooks/UseProductSearch";
import {KlarPrisProduct} from "Apis/Models/KlarPrisIntegrationModel";
import {roundNumber} from "Utils/NumberUtils";
import {ProductModel} from "Apis/Models/ProductModel";
import {getLinesTotalExVat} from "../../../Utils/InvoiceUtils";

interface Props {
    idKey: string | undefined;
    id: number;
    index: number;
    register: any;
    errors: any;
    control: any;
    remove: any,
    text: string;
    amount: number;
    unitSalesPrice: number;
    discount: number;
    setValue: any;
    getValues: any
    watch: any;
}
const EditInvoiceLine = ({idKey, id, index, register, errors, control, remove, text, amount, unitSalesPrice, discount, setValue, getValues, watch}: Props) => {

    const discountLineText = "19% ELi A/S til ELi A/S håndværker rabat"
    const isInternalDiscountLine = text === discountLineText;

    const lines = watch('lines');

    const [descriptionProductSuggestionsMenu, searchForProducts] = useProductsSearchMenu(async (product: KlarPrisProduct) => {
        setLineValuesFromProduct(product);
    }, (product: ProductModel) => {
        setValue(`lines[${index}].text`, product.description)
        setValue(`lines[${index}].unitSalesPrice`, product.unitPriceExVat)
    });

    const setLineValuesFromProduct = (product: KlarPrisProduct) => {
        const bestPrice = product.prices.sort((a,b) => a.price - b.price)[0];

        setValue(`lines[${index}].text`, product.description)
        setValue(`lines[${index}].unitSalesPrice`, roundNumber(bestPrice.price * 1.5))


        searchForProducts('')
    }

    useEffect(() => {
        if (isInternalDiscountLine) {
            const discountLine = lines[index];

            if (!discountLine) {
                return;
            }

            const total = getLinesTotalExVat(lines.filter(x => x.text !== discountLineText));
            const totalDiscount = ((total * 0.19) * -1)
            const roundedDiscount = Math.round((totalDiscount + Number.EPSILON) * 100) / 100;

            if (total !== 0 && parseFloat(discountLine.unitSalesPrice) !== roundedDiscount) {
                setValue(`lines[${index}].amount`, 1)
                setValue(`lines[${index}].unitSalesPrice`, roundedDiscount)
            }
        }
    }, [index, isInternalDiscountLine, lines, setValue]);

    return (
        <React.Fragment key={idKey}>
            <Dropdown overlay={descriptionProductSuggestionsMenu}>
                <Row gutter={[10, 10]} style={{width: '100%'}}>
                    <input type="hidden" name={`lines[${index}].id`} ref={register()} defaultValue={id}/>
                    <input type="hidden" name={`lines[${index}].lineNumber`} ref={register()} defaultValue={index} />
                    <Col span={10}>
                        <FlexRow style={{gap: 10}} align={"center"}>
                            <input type="text" onFocus={e => isInternalDiscountLine &&  e.currentTarget.blur()}  onChange={e => searchForProducts(e.target.value)}
                                   className={getPaperInputClasses(errors.lines && errors.lines[index]?.text)} name={`lines[${index}].text`} ref={register({required: true, minLength: 2})} defaultValue={text} />
                        </FlexRow>
                    </Col>
                    <Col span={3}>
                        <input type="number"  onFocus={e => isInternalDiscountLine && e.currentTarget.blur()}  className={getPaperInputClasses(errors.lines && errors.lines[index]?.amount)} name={`lines[${index}].amount`} ref={register({required: true, min: 0.1})} defaultValue={amount} />
                    </Col>
                    <Col span={3}>
                        <input type="number"  onFocus={e => isInternalDiscountLine && e.currentTarget.blur()}  className={getPaperInputClasses(errors.lines && errors.lines[index]?.unitSalesPrice)} name={`lines[${index}].unitSalesPrice`} ref={register({required: true})} defaultValue={unitSalesPrice} />
                    </Col>
                    <Col span={3}>
                        <FlexRow>
                            <input type="number" onFocus={e =>isInternalDiscountLine &&  e.currentTarget.blur()} className={getPaperInputClasses(errors.lines && errors.lines[index]?.discount)} name={`lines[${index}].discount`} ref={register({required: false, min: 0, max: 100})} defaultValue={discount} />
                        </FlexRow>
                    </Col>
                    <Col span={4}>
                        <span style={{height: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                            <FormLineTotal control={control} index={index} />
                        </span>
                    </Col>
                    <Col span={1}>
                        <span style={{height: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                            <span className={"clickable"} onClick={() => remove(index)} style={{marginLeft: 5}}>X</span>
                        </span>
                    </Col>
                </Row>
            </Dropdown>
        </React.Fragment>
    )
}

export default EditInvoiceLine
