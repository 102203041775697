import React from "react";
import MenuLink from "Pages/Layouts/MenuLink";
import User from "Assets/Icons/User";
import SubMenuLink from "Pages/Layouts/SubMenuLink";
import Payout from "Assets/Icons/Payout";
import Building from "Assets/Icons/Building";
import Approve from "Assets/Icons/Approve";
import Bullhorn from "Assets/Icons/Bullhorn";
import Report from "Assets/Icons/Report";
import Calendar from "Assets/Icons/Calendar";
import { useUserProvider } from "Providers/UserProvider";

const AdminMenu = () => {
    
    const { userContext } = useUserProvider();
    
    const getApprovalLinks = () => {
        const links = [
            <SubMenuLink key={'offers'} to={"/admin/approval/offers"}>Tilbud & Overslag</SubMenuLink>,
            <SubMenuLink key={'invoices'} to={"/admin/approval/invoices"}>Faktura & Kreditnota</SubMenuLink>,
            <SubMenuLink key={'reminders'} to={"/admin/approval/reminders"}>Rykkere & Inkassovarsel</SubMenuLink>,
        ]
        
        if (userContext!.permissions.filter(x => x.permissionType === "Deprecation").length > 0) {
            links.push(<SubMenuLink key={'deprecation'} to={"/admin/approval/deprecation"}>Afskriv faktura</SubMenuLink>)
        }
        
        return links;
    }
    
    return (
        <React.Fragment>
            <MenuLink icon={<Bullhorn />} to={"/admin/news"}>Nyheder</MenuLink>
            <MenuLink icon={<Calendar />} to={"/admin/calender"}>Kalender</MenuLink>
            <MenuLink icon={<User />} to={"/admin/users"}>Brugere</MenuLink>
            <MenuLink icon={<Building />} to={"/admin/organizations"}>Firmaer</MenuLink>
            <MenuLink icon={<Approve />} to={"/admin/supplierApplications"}>Ansøgninger</MenuLink>
            <MenuLink icon={<Payout />} to={"/admin/payouts"}>Betalinger</MenuLink>
            <MenuLink icon={<Approve />} to={"/admin/approval"} subLinks={getApprovalLinks()}>Godkendelse</MenuLink>
            <MenuLink icon={<Report/>} to={"/admin/Documents/"}>Dokumenter</MenuLink>
            <MenuLink icon={<Report/>} to={"/admin/qaSchemes"}>Kvalitetsikring</MenuLink>
            <MenuLink icon={<Report/>} to={"/admin/invoices"}>Faktura</MenuLink>
        </React.Fragment>
    )
}


export default AdminMenu;
