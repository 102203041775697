import React from 'react';
import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import SupplierInvoiceOverview from "Pages/Purchase/SupplierInvoices/SupplierInvoiceOverview";
import SupplierInvoiceDetails from "Pages/Purchase/SupplierInvoices/SupplierInvoiceDetails";
import KlarPrisPage from "Pages/Purchase/KlarPrisPage";
import UpdateSupplierInvoicePage from "./SupplierInvoices/UpdateSupplierInvoicePage";
import SupplierRoutes from "./Suppliers/SupplierRoutes";

const PurchaseRoutes = () => {

    let {path} = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}`} exact>
                <Redirect to={"/purchases/supplierinvoices"} />
            </Route>

            <Route path={`${path}/supplierinvoices`} exact>
                <SupplierInvoiceOverview />
            </Route>

             <Route path={`${path}/supplierinvoices/edit/:id`} exact>
                <UpdateSupplierInvoicePage />
            </Route>

            <Route path={`${path}/supplierinvoices/:id`} exact>
                <SupplierInvoiceDetails />
            </Route>

            <Route path={`${path}/suppliers`}>
                <SupplierRoutes />
            </Route>

            <Route path={`${path}/klarpris`}>
                <KlarPrisPage />
            </Route>
        </Switch>
    )
}

export default PurchaseRoutes
