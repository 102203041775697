import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import InvoicesAdminPage from "./InvoicesAdminPage";

const InvoicesRouteAdmin = () => {

    let { path } = useRouteMatch();

    return (<>
        <Switch>
            <Route path={`${path}`} exact>
                <InvoicesAdminPage />
            </Route>
        </Switch>
    </>)
}
export default InvoicesRouteAdmin;