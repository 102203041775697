import { useQuery } from "react-query";
import { request } from "Apis/BaseApi";
import {OrganizationRevenueInvoiceModel} from "./Models/OrganizationRevenueInvoiceModel";
import {auth} from "../../../FirebaseAuth";
import config from "Config";

export const ORGANIZATION_REVENUE_CACHE_KEY = 'ORGANIZATION_REVENUE_CACHE_KEY';
export const useOrganizationRevenue = (organizationId: number) => {
    return useQuery<OrganizationRevenueInvoiceModel[]>([ORGANIZATION_REVENUE_CACHE_KEY, organizationId], async () => {
        return await request<OrganizationRevenueInvoiceModel[]>({
            url: `/admin/organizations/${organizationId}/revenue/invoices`,
            method: 'GET'
        })
    })
}

export const downloadRevenueInvoice = async (organizationId: number, invoiceId: string) => {
    const token = await auth.currentUser?.getIdToken() ?? '';

    let headers = {
        Authorization: `Bearer ${token}`
    };

    const response = await fetch(config.baseUrl + `/admin/organizations/${organizationId}/revenue/invoices/${invoiceId}`, {
        headers
    })

    return await response.blob();
}