import saveAs from 'file-saver';

export function toBase64(file: File, includeData: boolean = false): Promise<any> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
            // @ts-ignore
            let payload = reader.result.split(',')[1];

            if (includeData) {
                payload = reader.result;
            }

            resolve(payload);
        }
        reader.onerror = error => reject(error);
    });
}

export const base64toFile = async (base64: string, fileName: string) => {
    const res: Response = await fetch(base64);
    const blob: Blob = await res.blob();
    saveAs(blob, fileName);
}

export const b64toBlob = (b64Data: string, contentType='', sliceSize=512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays: any[] = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}