import OrganizationModel from "Apis/Models/OrganizationModel";
import { Table } from "antd";
import Spinner from "Components/Spinner";
import moment from "moment";
import React from "react";
import FlexRow from "Components/FlexRow";
import {
    downloadRevenueInvoice,
    useOrganizationRevenue
} from "Apis/Admin/OrganizationRevenue/AdminOrganizationRevenueApi";
import Pill from "Components/Pill";
import {OrganizationRevenueInvoiceModel} from "Apis/Admin/OrganizationRevenue/Models/OrganizationRevenueInvoiceModel";
import {getNumberAsCurrency} from "Utils/NumberUtils";
import { saveAs } from 'file-saver';

interface Props {
    organization: OrganizationModel;
}
const OrganizationRevenue = ({organization}: Props) => {
    
    const { data, isLoading } = useOrganizationRevenue(organization.id);

    const downloadInvoice = async (invoice: OrganizationRevenueInvoiceModel) => {
        const blob = await downloadRevenueInvoice(organization.id, invoice.externalId)

        saveAs(blob, `${organization.name} - Faktura ${invoice.invoiceNumber}.pdf`);
    }

    return (
        <>
            <Table
                size={"small"}
                rowKey={'externalId'}
                dataSource={data}
                loading={{indicator: <Spinner />, spinning: isLoading}}
                pagination={false}
                onRow={(record) => {
                    return {
                        onClick: () => downloadInvoice(record),
                    };}
                }
            >
                <Table.Column title={"Faktura"} dataIndex={"invoiceNumber"} render={(text, record: OrganizationRevenueInvoiceModel) => (
                        <FlexRow justify={"start"}>
                            <Pill color={record.createdBySystem ? 'Default' : 'Danger'} />
                            <FlexRow align={"start"} justify={"start"} direction={"column"}>
                                <span>
                                    #{text}
                                </span>
                                <span style={{fontSize: '.7em'}}>
                                    {record.createdBySystem ? 'Genereret i ASAP\'EN' : 'Oprettet uden om ASAP\'en'}
                                </span>
                            </FlexRow>
                        </FlexRow>
                )}/>
                <Table.Column title={"Dato"} dataIndex={"date"} render={text => moment(text).format('DD/MM/YYYY')}/>
                <Table.Column title={"Kontakt / Kunde"} dataIndex={"contactName"} render={text => text} />
                <Table.Column title={"Total ekskl. moms"} dataIndex={"totalExclVat"} render={text => getNumberAsCurrency(text)}/>
                <Table.Column title={"Total inkl. moms"} dataIndex={"totalInclVat"} render={text => getNumberAsCurrency(text)}/>
            </Table>
        </>
    )
}

export default OrganizationRevenue
