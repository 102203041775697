import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import FlexRow from "Components/FlexRow";
import {Table} from "antd";
import Spinner from "Components/Spinner";
import React, {useCallback, useState} from "react";
import {useHistory} from "react-router-dom";
import Box from "Components/Box";
import _ from 'lodash';
import StyledButton from "Components/StyledButton";
import Purchase from "Assets/Icons/Purchase";
import {SupplierModel} from "Apis/Models/SupplierModel";
import {useSuppliers} from "Apis/SupplierApi";

const SuppliersOverviewPage = () => {

    const history = useHistory();

    const [searchFilter, setSearchFilter] = useState<string>('')

    const {data: suppliers, isLoading} = useSuppliers(searchFilter);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const searchForCustomers = useCallback(_.debounce(async (searchFilter: string) => {
        setSearchFilter(searchFilter)
    }, 300), [])

    return (
        <DefaultPageLayout title={"Leverandører"} icon={<Purchase />}
            beforeBox={
                <Box style={{marginBottom: 15, padding: 10}}>
                    <input onChange={e => searchForCustomers(e.target.value)} type="text" className={"input input-invisible full-width"} placeholder={"Søg...."}/>
                </Box>
            }
           fab={
               <StyledButton color={"primary"} onClick={() => history.push('/purchases/suppliers/create')}>
                   Opret leverandør
               </StyledButton>
           }
        >
            <Table
                rowKey={'id'}
                dataSource={suppliers}
                loading={{indicator: <Spinner/>, spinning: isLoading}}
                pagination={false}
                onRow={(record) => {
                    return {
                        onClick: () => history.push(`/purchases/suppliers/edit/${record.id}`),
                    };
                }}
                rowClassName={'clickable'}
            >
                <Table.Column title={"Navn"} dataIndex={"name"} render={(text) => (
                    <FlexRow direction={"column"} justify={"start"} align={"start"}>
                        <b>
                            {text}
                        </b>
                    </FlexRow>
                )}/>
                <Table.Column title={"Adresse"} dataIndex={"address"} render={(text, record: SupplierModel) => (
                    <FlexRow direction={"column"} justify={"start"} align={"start"}>
                        {record.street}
                        <span style={{fontSize: '.8em'}}>
                            {record.zipCode} - {record.city}
                        </span>
                    </FlexRow>
                )}/>
                <Table.Column title={"CVR"} dataIndex={"vatNumber"}/>
            </Table>
        </DefaultPageLayout>
    )
}

export default SuppliersOverviewPage;
