import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import ShoppingList from "Assets/Icons/ShoppingList";
import React from "react";
import {useHistory, useParams} from "react-router-dom";
import {useDeleteOrganization, useOrganization} from "Apis/OrganizationAdminApi";
import OrganizationForm from "Pages/Admin/Organizations/Forms/OrganizationForm";
import StyledButton from "Components/StyledButton";
import FlexRow from "Components/FlexRow";
import {openSuccessNotification} from "Utils/NotificationUtils";
import { Tabs } from "antd";
import OrganizationContributions from "./OrganizationContributions";
import OrganizationUsers from "./OrganizationUsers";
import OrganizationRevenue from "./OrganizationRevenue";

const EditOrganizationPage = () => {

    const { id } = useParams<{ id }>();
    const history = useHistory();

    const {data: organization, isLoading} = useOrganization(id);

    const [deleteOrganizationMutation, {isLoading: isDeletingOrganization}] = useDeleteOrganization();

    const deleteOrganization = async () => {
        await deleteOrganizationMutation(organization);

        openSuccessNotification('Success', 'Firmaet blev slettet');

        history.goBack();
    }

    return (
        <DefaultPageLayout title={"Opdater firma"} isLoading={isLoading} icon={<ShoppingList />}
            fab={<FlexRow justify={"end"}><StyledButton onClick={deleteOrganization} loading={isDeletingOrganization} color={"danger"}>Slet firma</StyledButton></FlexRow>}
        >
            <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="Firmaoplysninger" key="1">
                    <OrganizationForm organization={organization} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Brugere" key="2">
                    <OrganizationUsers organization={organization!} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="ELi A/S bidrag" key="3">
                    <OrganizationContributions organization={organization!} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Omsætning fra regnskab" key="4">
                    <OrganizationRevenue organization={organization!} />
                </Tabs.TabPane>
            </Tabs>
        </DefaultPageLayout>
    )

}
export default EditOrganizationPage;
