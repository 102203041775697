import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import React from "react";
import { useParams } from "react-router-dom";
import Purchase from "../../../Assets/Icons/Purchase";
import { useSupplier } from "Apis/SupplierApi";
import SupplierForm from "./Forms/SupplierForm";

const EditSupplierPage = () => {

    const { id } = useParams<{ id }>();

    const {data: supplier, isLoading} = useSupplier(id);

    return (
        <DefaultPageLayout
            isLoading={isLoading}
            title={"Opdater leverandør"}
            icon={<Purchase />}
        >
            <SupplierForm supplier={supplier} />
        </DefaultPageLayout>
    )
}

export default EditSupplierPage;
